const pedidos = async ({ token }) => {
  try {
    const res = await fetch(`${process.env.REACT_APP_API_URL}/v1/pedidos?api_token=${token}`);

    const data = await res.json();

    if (data.error) {
      throw new Error(data.error);
    }

    return data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export default pedidos;
