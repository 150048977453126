import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Alert,
} from "reactstrap";
import { CHAMADOS_ROUTE } from "../router";
import { isAuthenticated } from "../api";
import produtos from "../api/produtos";
import inserirPedido from "../api/inserir-pedido";
import pedidos from "../api/pedidos";
import moment from "moment";

class Pedidos extends Component {
  state = {
    produtosList: [],
    pedidosList: [],
    loadingProdutos: true,
    loadingPedidos: false,
    saving: false,
    formData: {
      produtoId: 0,
      quantidade: "",
    },
  };

  async componentDidMount() {
    try {
      const token = localStorage.getItem("token") || null;
      await isAuthenticated({ token });

      const produtosList = await produtos({ token });

      this.setState({ produtosList, loadingProdutos: false });

      await this.getPedidos();
    } catch (error) {
      this.props.onLogout();
    }
  }

  async getPedidos() {
    try {
      const token = localStorage.getItem("token") || null;
      await isAuthenticated({ token });

      const pedidosList = await pedidos({ token });

      this.setState({ pedidosList, loadingPedidos: false });
    } catch (error) {
      this.props.onLogout();
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();

    const body = {
      ...this.state.formData,
    };

    let messageError = "";

    if (!parseInt(body.produtoId, 10)) {
      messageError += "- Informe o produto\n";
    }

    if (!parseInt(body.quantidade, 10)) {
      messageError += "- Informe a quantidade\n";
    }

    if (messageError.length > 0) {
      alert(`Por favor, verifique:\n\n${messageError}`);
      return;
    }

    const token = localStorage.getItem("token") || null;

    this.setState({ saving: true }, async () => {
      try {
        const inserido = await inserirPedido({ body, token });

        if (inserido) {
          await this.getPedidos();
          this.setState({
            saving: false,
            formData: {
              produtoId: 0,
              quantidade: "",
            },
          });
        } else {
          alert("Não foi possível inserir o pedido");
          this.setState({ saving: false });
        }
      } catch (error) {
        alert("Ops! Ocorreu um erro");
        this.setState({ saving: false });
      }
    });
  };

  handleChangeInput = (field, event) => {
    this.setState({
      formData: {
        ...this.state.formData,
        [field]: event.target.value,
      },
    });
  };

  handleChangeProduto = (event) => {
    const produtoId = event.currentTarget.value;

    this.setState({
      formData: { ...this.state.formData, produtoId },
    });
  };

  render() {
    const { history } = this.props;
    const {
      produtosList,
      pedidosList,
      loadingPedidos,
      loadingProdutos,
      saving,
      formData
    } = this.state;

    return (
      <Container fluid>
        <Row>
          <Col>
            <span className="h3">Pedidos</span>
            <Button
              color="warning"
              style={{ marginBottom: 10, marginLeft: 10 }}
              onClick={() => history.push(CHAMADOS_ROUTE)}
            >
              <strong>Acompanhar Chamados</strong>
            </Button>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form
              id="form-pedido"
              method="post"
              onSubmit={this.handleSubmit}
              style={{ marginTop: 30 }}
            >
              <Row>
                <Col>
                  <FormGroup>
                    <Label for="produto">Produto</Label>
                    <Input
                      type="select"
                      name="produto"
                      id="produto"
                      onChange={(event) => this.handleChangeProduto(event)}
                      value={formData.produtoId}
                    >
                      <option>
                        {loadingProdutos ? "Carregando..." : "Selecione"}
                      </option>
                      {produtosList.map((item) => (
                        <option key={item.cd_prd_web} value={item.cd_prd_web}>
                          {item.desc_prd_web}
                        </option>
                      ))}
                    </Input>
                  </FormGroup>
                </Col>
                <Col xs={2}>
                  <FormGroup>
                    <Label for="quantidade">Quantidade</Label>
                    <Input
                      type="text"
                      name="quantidade"
                      id="quantidade"
                      value={formData.quantidade}
                      onChange={(event) =>
                        this.handleChangeInput("quantidade", event)
                      }
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <Button
                    color="success"
                    type="submit"
                    disabled={saving}
                    style={{ marginTop: 32 }}
                  >
                    <strong>{saving ? "Adicionando..." : "Adicionar"}</strong>
                  </Button>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
        <Row>
          <Col>
            {!loadingPedidos &&
              pedidosList.length > 0 && (
                <div className="table-responsive-sm">
                  <table className="table table-sm">
                    <thead className="thead-light">
                      <tr>
                        <th scope="col">No.</th>
                        <th scope="col">Data do Pedido</th>
                        <th scope="col">Produto</th>
                        <th scope="col">Quantidade</th>
                        <th scope="col">Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {pedidosList.map((item) => (
                        <tr key={item.id_pedido_web} className="button">
                          <th scope="row">{item.id_pedido_web}</th>
                          <td>
                            {moment(item.dt_pedido_web).format("DD/MM/YYYY")}
                          </td>
                          <td>{item.desc_prd_web}</td>
                          <td>{item.qtd}</td>
                          <td>{item.status}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}

            {loadingPedidos && <p>Carregando...</p>}

            {!loadingPedidos &&
              pedidosList.length === 0 && (
                <Alert color="info">Não há pedidos no momento.</Alert>
              )}
          </Col>
        </Row>
      </Container>
    );
  }
}

export default Pedidos;
