import React, { Component } from "react";
import Router from "./router";
import BaseLayout from "./components/BaseLayout";

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      authenticated: localStorage.getItem("token") ? true : false,
      username: localStorage.getItem("username") || null,
      token: null
    };
  }

  onLoginSuccess = ({ token, cliente }) => {
    localStorage.setItem("token", token);
    localStorage.setItem("username", cliente);
    this.setState({ authenticated: true, token, username: cliente });
  };

  onLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("username");
    this.setState({ authenticated: false, token: null, username: null });
  };

  render() {
    const { authenticated, username } = this.state;

    return (
      <BaseLayout
        showHeader={authenticated}
        onLogout={this.onLogout}
        username={username}
      >
        <Router
          authenticated={authenticated}
          onLoginSuccess={this.onLoginSuccess}
          onLogout={this.onLogout}
        />
      </BaseLayout>
    );
  }
}

export default App;
