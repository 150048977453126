const inserirChamado = async ({ body, token }) => {
  try {
    const res = await fetch(`${process.env.REACT_APP_API_URL}/v1/inserir-chamado?api_token=${token}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify(body)
    });

    const data = await res.json();

    if (data.error) {
      throw new Error(data.error);
    }

    return data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export default inserirChamado;
