import React from "react";

import { HashRouter, Switch } from "react-router-dom";

import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";

import Login from "../screens/Login";
import Chamados from "../screens/Chamados";
import ScrollToTop from "./ScrollToTop";
import AbrirChamado from "../screens/AbrirChamado";
import ChamadoDetalhes from "../screens/ChamadoDetalhes";
import Pedidos from "../screens/Pedidos";

export const CHAMADOS_ROUTE = "/";
export const ABRIR_CHAMADO_ROUTE = "/abrir-chamado";
export const PEDIDOS_ROUTE = "/pedidos";
export const CHAMADO_DETALHES_ROUTE = "/detalhes-do-chamado/:id";
export const LOGIN_ROUTE = "/login";

const Router = ({ authenticated, onLoginSuccess, onLogout }) => (
  <HashRouter>
    <ScrollToTop>
      <Switch>
        <PrivateRoute
          exact
          path={CHAMADOS_ROUTE}
          component={props => <Chamados {...props} onLogout={onLogout} />}
          authenticated={authenticated}
        />
        <PrivateRoute
          path={ABRIR_CHAMADO_ROUTE}
          component={props => <AbrirChamado {...props} onLogout={onLogout} />}
          authenticated={authenticated}
        />
        <PrivateRoute
          path={PEDIDOS_ROUTE}
          component={props => <Pedidos {...props} onLogout={onLogout} />}
          authenticated={authenticated}
        />
        <PrivateRoute
          path={CHAMADO_DETALHES_ROUTE}
          component={props => (
            <ChamadoDetalhes {...props} onLogout={onLogout} />
          )}
          authenticated={authenticated}
        />
        <PublicRoute
          path={LOGIN_ROUTE}
          component={props => <Login {...props} onSuccess={onLoginSuccess} />}
          authenticated={authenticated}
        />
      </Switch>
    </ScrollToTop>
  </HashRouter>
);

export default Router;
