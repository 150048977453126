import React, { Fragment } from "react";
import { Container, Row, Col, Button } from "reactstrap";
import "./BaseLayout.css";
import logo from "../logo.png";

const BaseLayout = ({ children, showHeader, onLogout, username }) => (
  <Fragment>
    {showHeader && (
      <Container fluid className="Header-container">
        <Row>
          <Col>
            <div className="Header">
              <div className="Header-left">
                <img src={logo} alt="Senhor Express" className="Header-brand" />
              </div>
              <div className="Header-right">
                <span>Olá, {username}</span>{" "}
                <Button
                  outline
                  color="warning"
                  size="sm"
                  style={{ marginLeft: 10 }}
                  onClick={() => onLogout()}
                >
                  Sair
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    )}
    {children}
  </Fragment>
);

export default BaseLayout;
