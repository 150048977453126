import React, { Component } from "react";
import moment from "moment";
import { Container, Row, Col, Button, Alert } from "reactstrap";
import { ABRIR_CHAMADO_ROUTE, CHAMADO_DETALHES_ROUTE, PEDIDOS_ROUTE } from "../router";
import { acompanharChamados } from "../api";

class Chamados extends Component {
  state = {
    chamados: [],
    loading: true
  };

  componentDidMount() {
    this.loadChamados();
  }

  loadChamados = async () => {
    try {
      const data = await acompanharChamados({
        token: localStorage.getItem("token")
      });

      this.setState({
        chamados: data.map(d => {
          let data_hora_abertura = moment(d.data_hora_abertura);
          let data_fechado = moment(d.data_fechado);
          let prev_atendimento = moment(d.prev_atendimento);

          if (prev_atendimento < data_hora_abertura) {
            prev_atendimento = "-";
          } else {
            prev_atendimento = prev_atendimento.format("DD/MM/YYYY");
          }

          data_hora_abertura = data_hora_abertura.format("DD/MM/YYYY");
          data_fechado = d.status === 'Fechado' ? data_fechado.format("DD/MM/YYYY") : '-';

          return {
            ...d,
            data_fechado,
            data_hora_abertura,
            prev_atendimento
          };
        }),
        loading: false
      });
    } catch (error) {
      this.props.onLogout();
    }
  };

  render() {
    const { history } = this.props;
    const { chamados, loading } = this.state;

    return (
      <Container fluid>
        <Row>
          <Col>
            <span className="h3">Acompanhar Chamados</span>
            <Button
              color="warning"
              style={{ marginBottom: 10, marginLeft: 10 }}
              onClick={() => history.push(ABRIR_CHAMADO_ROUTE)}
            >
              <strong>Abrir Chamado</strong>
            </Button>
            <Button
              color="warning"
              style={{ marginBottom: 10, marginLeft: 10 }}
              onClick={() => history.push(PEDIDOS_ROUTE)}
            >
              <strong>Pedidos</strong>
            </Button>
          </Col>
        </Row>
        <Row>
          <Col>
            {!loading &&
              chamados.length > 0 && (
                <div className="table-responsive-sm">
                  <table className="table table-sm">
                    <thead className="thead-light">
                      <tr>
                        <th scope="col">No.</th>
                        <th scope="col">Usuário</th>
                        <th scope="col">Máquina</th>
                        <th scope="col">Tipo de Chamado</th>
                        {/* <th scope="col">Data da abertura</th> */}
                        <th scope="col">Encerrado em</th>
                        <th scope="col">Status</th>
                        <th scope="col">Prev. Atendimento</th>
                      </tr>
                    </thead>
                    <tbody>
                      {chamados.map(chamado => (
                        <tr
                          key={chamado.id}
                          className="button"
                          onClick={() => {
                            localStorage.setItem(
                              "chamado",
                              JSON.stringify(chamado)
                            );
                            history.push(
                              CHAMADO_DETALHES_ROUTE.replace(":id", chamado.id)
                            );
                          }}
                        >
                          <th scope="row">{chamado.id}</th>
                          <td>{chamado.usuario}</td>
                          <td>{chamado.maquina}</td>
                          <td>{chamado.tipo_chamado}</td>
                          {/* <td>{chamado.data_hora_abertura}</td> */}
                          <td>{chamado.data_fechado}</td>
                          <td>{chamado.status}</td>
                          <td>{chamado.prev_atendimento}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}

            {loading && <p>Carregando...</p>}

            {!loading &&
              chamados.length === 0 && (
                <Alert color="info">Não há chamados para acompanhamento.</Alert>
              )}
          </Col>
        </Row>
      </Container>
    );
  }
}

export default Chamados;
