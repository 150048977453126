const maquinasPorCliente = async ({ tipo, token }) => {
  try {
    if (!parseInt(tipo)) {
      return [];
    }

    const res = await fetch(
      `${process.env.REACT_APP_API_URL}/v1/maquinas-por-cliente?api_token=${token}&tipo=${tipo}`
    );

    const data = await res.json();

    if (data.error) {
      throw new Error(data.error);
    }

    return data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export default maquinasPorCliente;
