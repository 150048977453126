import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Alert
} from "reactstrap";

import "./Login.css";
import logo from "../logo.png";
import auth from "../api/auth";

class Login extends Component {
  state = {
    username: "",
    password: "",
    error: null,
    loading: false
  };

  handleChange = event => {
    const { name, value } = event.target;
    this.setState({ [name]: value, error: null });
  };

  handleSubmit = event => {
    event.preventDefault();

    const { username, password } = this.state;
    const validateUsername = username.trim().length !== 0;
    const validatePassword = password.trim().length !== 0;

    if (!validateUsername || !validatePassword) {
      this.setState({ error: "Por favor, informe o usuário e senha!" });
      return;
    }

    this.setState({ loading: true, error: null }, async () => {
      try {
        const data = await auth({ username, password });
        this.props.onSuccess(data);
      } catch (error) {
        this.setState({ error: error.message, loading: false });
      }
    });
  };

  render() {
    const { error, loading } = this.state;

    return (
      <Container fluid className="Login-container">
        <Row>
          <Col md={{ size: 4, offset: 4 }}>
            <div style={{ textAlign: "center" }}>
              <img src={logo} alt="Senhor Express" className="Login-brand" />
            </div>
          </Col>
        </Row>
        <br />
        <Row>
          <Col md={{ size: 4, offset: 4 }}>
            <Form onSubmit={this.handleSubmit} method="post">
              <FormGroup>
                <Label for="username">Usuário</Label>
                <Input
                  type="text"
                  name="username"
                  id="username"
                  placeholder="Digite seu usuário"
                  onChange={this.handleChange}
                />
              </FormGroup>
              <FormGroup>
                <Label for="password">Senha</Label>
                <Input
                  type="password"
                  name="password"
                  id="password"
                  placeholder="Digite sua senha"
                  onChange={this.handleChange}
                />
              </FormGroup>

              <Button
                type="submit"
                color="warning"
                size="lg"
                block
                disabled={loading}
              >
                <strong>{loading ? "Aguarde..." : "Acessar"}</strong>
              </Button>

              {error && (
                <Alert
                  color="warning"
                  style={{ marginTop: 15, textAlign: "center" }}
                >
                  {error}
                </Alert>
              )}
            </Form>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default Login;
