import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  Label,
  Input
} from "reactstrap";
import { CHAMADOS_ROUTE } from "../router";
import { isAuthenticated, tiposChamado } from "../api";
import maquinasPorCliente from "../api/maquinas-por-cliente";
import inserirChamado from "../api/inserir-chamado";

class AbrirChamado extends Component {
  state = {
    tiposChamadoList: [],
    equipamentosList: [],
    loadingTiposChamado: true,
    loadingEquipamentos: false,
    saving: false,
    formData: {
      tipoChamadoId: 0,
      equipamentoId: 0,
      descricao: ""
    }
  };

  async componentDidMount() {
    try {
      const token = localStorage.getItem("token") || null;
      await isAuthenticated({ token });

      const tiposChamadoList = await tiposChamado({ token });

      this.setState({ tiposChamadoList, loadingTiposChamado: false });
    } catch (error) {
      this.props.onLogout();
    }
  }

  handleSubmit = e => {
    e.preventDefault();

    const { history } = this.props;

    const body = {
      ...this.state.formData
    };

    let messageError = "";

    if (!parseInt(body.tipoChamadoId, 10)) {
      messageError += "- Informe o tipo do chamado\n";
    }

    if (!parseInt(body.equipamentoId, 10)) {
      messageError += "- Informe o equipamento\n";
    }

    if (body.descricao.trim().length === 0) {
      messageError += "- Informe uma breve descrição para o chamado\n";
    }

    if (messageError.length > 0) {
      alert(`Por favor, verifique:\n\n${messageError}`);
      return;
    }

    const token = localStorage.getItem("token") || null;

    this.setState({ saving: true }, async () => {
      try {
        const inserido = await inserirChamado({ body, token });

        if (inserido) {
          history.push(CHAMADOS_ROUTE);
        } else {
          alert("Não foi possível inserir o chamado");
          this.setState({ saving: false });
        }
      } catch (error) {
        alert("Ops! Ocorreu um erro");
        this.setState({ saving: false });
      }
    });
  };

  handleChangeInput = (field, event) => {
    this.setState({
      formData: {
        ...this.state.formData,
        [field]: event.target.value
      }
    });
  };

  handleChangeTipoChamado = event => {
    const token = localStorage.getItem("token") || null;
    const tipo = event.currentTarget.value;

    this.setState(
      {
        equipamentosList: [],
        loadingEquipamentos: true,
        formData: { ...this.state.formData, tipoChamadoId: tipo }
      },
      async () => {
        const equipamentosList = await maquinasPorCliente({
          tipo,
          token
        });

        this.setState({ equipamentosList, loadingEquipamentos: false });
      }
    );
  };

  render() {
    const { history } = this.props;
    const {
      tiposChamadoList,
      equipamentosList,
      loadingEquipamentos,
      loadingTiposChamado,
      saving
    } = this.state;

    return (
      <Container fluid>
        <Row>
          <Col>
            <span className="h3">Abrir Chamado</span>
            <Button
              color="warning"
              style={{ marginBottom: 10, marginLeft: 10 }}
              onClick={() => history.push(CHAMADOS_ROUTE)}
            >
              <strong>Acompanhar Chamados</strong>
            </Button>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form id="form-chamado" method="post" onSubmit={this.handleSubmit}>
              <FormGroup>
                <Label for="tipo_chamado">Tipo de Chamado</Label>
                <Input
                  type="select"
                  name="tipo_chamado"
                  id="tipo_chamado"
                  onChange={event => this.handleChangeTipoChamado(event)}
                >
                  <option>
                    {loadingTiposChamado ? "Carregando..." : "Selecione"}
                  </option>
                  {tiposChamadoList.map(item => (
                    <option key={item.id} value={item.id}>
                      {item.descricao}
                    </option>
                  ))}
                </Input>
              </FormGroup>
              <FormGroup>
                <Label for="equipamento">Equipamento</Label>
                <Input
                  type="select"
                  name="equipamento"
                  id="equipamento"
                  onChange={event =>
                    this.handleChangeInput("equipamentoId", event)
                  }
                >
                  <option>
                    {loadingEquipamentos ? "Carregando..." : "Selecione"}
                  </option>
                  {equipamentosList.map(item => (
                    <option key={item.id} value={item.id}>
                      {item.descricao}
                    </option>
                  ))}
                </Input>
              </FormGroup>
              <FormGroup>
                <Label for="descricao">Breve Descrição</Label>
                <Input
                  type="textarea"
                  name="descricao"
                  id="descricao"
                  rows={10}
                  onChange={event => this.handleChangeInput("descricao", event)}
                />
              </FormGroup>
              <Button color="success" type="submit" disabled={saving}>
                <strong>{saving ? "Aguarde..." : "Confirmar"}</strong>
              </Button>
              <Button
                color="link"
                onClick={() => history.push(CHAMADOS_ROUTE)}
                disabled={saving}
              >
                <strong>Cancelar</strong>
              </Button>
            </Form>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default AbrirChamado;
