import React from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  Label
} from "reactstrap";
import { Redirect } from "react-router-dom";
import { CHAMADOS_ROUTE } from "../router";

const ChamadoDetalhes = ({ history }) => {
  const chamado = JSON.parse(localStorage.getItem("chamado"));

  if (!chamado) {
    return <Redirect to={CHAMADOS_ROUTE} />;
  }

  return (
    <Container fluid>
      <Row>
        <Col>
          <span className="h3">Detalhes do Chamado #{chamado.id}</span>
          <Button
            color="warning"
            style={{ marginBottom: 10, marginLeft: 10 }}
            onClick={() => history.push(CHAMADOS_ROUTE)}
          >
            <strong>Acompanhar Chamados</strong>
          </Button>
        </Col>
      </Row>
      <hr />
      <Row>
        <Col>
          <Form>
            <Row form>
              <Col sm={3}>
                <FormGroup row>
                  <Label sm={12}>
                    Usuário: <strong>{chamado.usuario}</strong>
                  </Label>
                </FormGroup>
              </Col>
              <Col sm={3}>
                <FormGroup row>
                  <Label sm={12}>
                    Tipo de atendimento: <strong>{chamado.tipo_chamado}</strong>
                  </Label>
                </FormGroup>
              </Col>
              {/* <Col sm={3}>
                <FormGroup row>
                  <Label sm={12}>
                    Data de abertura:{" "}
                    <strong>{chamado.data_hora_abertura}</strong>
                  </Label>
                </FormGroup>
              </Col> */}
            </Row>
            <Row form>
              <Col sm={3}>
                <FormGroup row>
                  <Label sm={12}>
                    Máquina: <strong>{chamado.maquina}</strong>
                  </Label>
                </FormGroup>
              </Col>
              <Col sm={3}>
                <FormGroup row>
                  <Label sm={12}>
                    Status: <strong>{chamado.status}</strong>
                  </Label>
                </FormGroup>
              </Col>
              <Col sm={3}>
                <FormGroup row>
                  <Label sm={12}>
                    Encerrado em:{" "}
                    <strong>{chamado.data_fechado}</strong>
                  </Label>
                </FormGroup>
              </Col>
            </Row>
            <Row form>
              <Col sm={6}>
                <FormGroup>
                  <Label>Descrição:</Label>
                  <p>
                    <strong>{chamado.descricao}</strong>
                  </p>
                </FormGroup>
              </Col>
              <Col sm={6}>
                <FormGroup>
                  <Label>Avaliação técnica:</Label>
                  <p><strong>{chamado.avaliacao_tecnica || "-"}</strong></p>
                </FormGroup>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default ChamadoDetalhes;
